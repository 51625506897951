import { file_format_keys, file_mime_types } from "../../File/File.constants";

export const ACCEPTED_IMAGES_FORMAT_IN_OPTIMIZATING_CDN = [
  ...file_mime_types[file_format_keys.png],
  ...file_mime_types[file_format_keys.jpeg],
  ...file_mime_types[file_format_keys.jpg],
  ...file_mime_types[file_format_keys.avif],
  ...file_mime_types[file_format_keys.svg],
  ...file_mime_types[file_format_keys.tiff],
  ...file_mime_types[file_format_keys.webp],
];
export const IMAGE_OPTIMIZATION_CDN_URL =
  process.env.REACT_APP_EXLY_CONTENT_IMAGES_CDN_URL;
export const NON_OPTIMIZATION_CDN_URL =
  process.env.REACT_APP_EXLY_CONTENT_CDN_URL;
export const IMAGE_OPTIMIZATION_S3_BUCKET =
  process.env.REACT_APP_S3_BUCKET_EXLY_CONTENT_IMAGES;
export const NON_OPTIMIZATION_S3_BUCKET =
  process.env.REACT_APP_S3_BUCKET_EXLY_CONTENT;

/**
 * Part of the upload url pathname for the secion uploading the image
 * More Section to be added based on which section is the image uploaded from.\
 * Note:
 * - Keep it without spaces and special characters.
 * - Use hyphens(-) and underscore(_) as separators
 */
export const IMAGE_SECTION_KEY_PATH = {
  HOME_PAGE: "home_page",
  LISTING_COVER_IMAGE: "listing_cover_image",
  SALES_PAGES: "sales_pages",
  CHECKOUT_PAGES: "checkout_pages",
  RTE: "rich_text_editor",
};

export const COMMON_WIDTH_RESOLUTIONS = {
  WIDTH_1080: 1080,
  WIDTH_1920: 1920,
};
